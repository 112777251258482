.invoices__container {
    .ant-tabs {
        .ant-tabs-nav {
            .ant-tabs-nav-wrap {
                padding: 0 2rem;

                .ant-tabs-tab.ant-tabs-tab-disabled {
                    display: none;
                }
            }
        }
    }

    .invoices__actions {
        padding: 0 2rem;

        .invoices__actions-invoice,
        .invoices__actions-filters {
            display: inline-flex;
        }

        .invoices__actions-invoice {
            .ant-btn {
                margin: 0 0 0 1rem;
            }
        }

        .invoices__actions-filters {
            .export-btn {
                margin: 0 0 0 .7rem;
            }
            .image-export-btn{
                margin: 0 0 0 .7rem;
            }
        }
    }

    .invocies__list {
        padding: 2rem;
    }
}